<template>
  <div ref="tableEl" class="table-list" :class="`table-list--${theme}`">
    <!-- thead -->
    <div
      v-if="items && items.length"
      class="table-list__thead"
      :style="{ 'grid-template-columns': gridTemplateColumnsComputed }"
    >
      <!-- th -->
      <template v-for="(column, i) in columns" :key="i">
        <div
          v-if="shouldDisplayColumn(column)"
          :class="[
            {
              'table-list__th': true,
              'table-list__th--sortable': column.value,
              'table-list__th--sorted': column.value && sortedBy === column.value,
            },
            typeof column.thClass === 'function' ? column.thClass(item) : column.thClass,
          ]"
          @click="column.value ? sort(column.value) : null"
        >
          <!-- Arrow -->
          <i
            v-if="column.value && sortedBy === column.value"
            :class="`fas fa-long-arrow-alt-${sortOrder === 'desc' ? 'down' : 'up'} icon`"
          >
          </i>

          {{ column.name ?? '' }}
        </div>
      </template>
    </div>

    <!-- tbody (empty) -->
    <template v-if="!loading && items && !items.length">
      <div class="table-list__tbody table-list__tbody--empty">
        <slot name="noitems"> No items in the list </slot>
      </div>
    </template>

    <!-- tbody -->
    <template v-else-if="items && items.length">
      <!-- tbody -->
      <div class="table-list__tbody">
        <!-- tr -->
        <div
          v-for="(item, i) in items"
          :key="i"
          :class="[
            {
              'table-list__tr': true,
              'table-list__tr--clickable': isRowClickable,
              'text-red-400': item.deleted_at,
            },
            typeof trClass === 'function' ? trClass(item) : trClass,
          ]"
          :style="{ 'grid-template-columns': gridTemplateColumnsComputed }"
          @click="onRowClick(item, i)"
        >
          <!-- td -->
          <template v-for="(column, j) in columns" :key="j">
            <div
              v-if="shouldDisplayColumn(column)"
              class="table-list__td"
              :class="typeof column.tdClass === 'function' ? column.tdClass(item) : column.tdClass"
            >
              <!-- If: button -->
              <template v-if="column.button">
                <a v-if="column.buttonType === 'link'">{{ column.buttonText }}</a>
                <button v-if="column.buttonType === 'button'">{{ column.buttonText }}</button>
              </template>

              <!-- Else if: html -->
              <template v-else-if="column.slotName">
                <slot :name="column.slotName" :item="item" :i="i" />
              </template>

              <!-- Else: column value -->
              <template v-else>
                <span v-if="item" class="table-list__td-text">
                  {{ column.get ? column.get(item) : column.value ? objectGet(item, column.value) : null }}
                </span>
              </template>
            </div>
          </template>
        </div>

        <!-- Permissions message -->
        <Alert v-if="items.length === maxItemsAllowed && items.length !== totalItems" type="warning">
          <template #body> Showing {{ maxItemsAllowed }} responses </template>
        </Alert>

        <!-- Infinite scroll -->
        <div ref="InfiniteScrollRef" style="height: 1px"></div>
      </div>
    </template>
  </div>
</template>

<script setup>
import axios from 'axios';
import { useStore } from 'vuex';
import { useResizeObserver, useIntersectionObserver } from '@vueuse/core';
import { queryBuilderToQueryString, objectGet } from '@/js/Helpers';
import { watch, computed, ref, toRefs, reactive, onMounted } from 'vue';
import { toast } from '@/js/Helpers/Alert';
import Alert from '@/js/Components/Alert.vue';
import * as Sentry from '@sentry/vue';
import { cn } from '@/js/lib/utils';

const props = defineProps({
  // Layout (required)
  columns: {
    type: Array,
    required: true,
  },
  // @DEPRECATED
  gridTemplateColumns: {
    type: String,
    default: null,
  },
  trClass: {
    type: [Array, String, Object, Function],
    default: null,
  },

  // Source (api)
  apiEndpoint: String,
  apiIncludes: Array,
  apiSortedBy: String,
  apiSortOrder: String,
  apiVuexProperty: String,
  apiVuexMutation: String,
  apiRefreshTrigger: Number,
  apiFilters: {
    type: Object,
    default: () => ({}),
  },
  apiLimitPerPage: {
    type: Number,
    default: 30,
  },
  apiNoInfiniteScroll: {
    type: Boolean,
    default: false,
  },

  // Style
  heightElement: String,
  theme: {
    type: String,
    default: 'material',
    validator: (theme) => ['material', 'embedded'].includes(theme),
  },
  isRowClickable: {
    type: Boolean,
    default: true,
  },

  // Behaviour
  newTabOnRowClick: {
    type: Function,
    default: null,
  },
});

const emit = defineEmits(['sort', 'item:click', 'items']);

const onRowClick = (item, i) => {
  emit('item:click', item, i);
  if (props.newTabOnRowClick) window.open(props.newTabOnRowClick(item, i));
};

// Store
const store = useStore();

const loading = ref(false);

// Table height
const tableEl = ref(null);
onMounted(() => {
  if (!props.heightElement) return;
  const el = document.querySelector(props.heightElement);
  useResizeObserver(el, () => {
    tableEl.value.style.height = `${
      el.getBoundingClientRect().bottom - tableEl.value.getBoundingClientRect().top - 30
    }px`;
  });
});

const shouldDisplayColumn = (column) => {
  if (column.if === undefined) return true;
  if (typeof column.if === 'function') return column.if();
  return column.if;
};

const gridTemplateColumnsComputed = computed(() => {
  if (props.gridTemplateColumns) return props.gridTemplateColumns;
  return props.columns
    .filter((column) => shouldDisplayColumn(column))
    .map((column) => column.width)
    .join(' ');
});

// Api config
const api = {
  endpoint: props.apiEndpoint,
  includes: props.apiIncludes,
  sortedBy: props.apiSortedBy,
  sortOrder: props.apiSortOrder,
  vuexProperty: props.apiVuexProperty,
  vuexMutation: props.apiVuexMutation,
  refreshTrigger: computed(() => props.apiRefreshTrigger),
  limitPerPage: props.apiLimitPerPage,
  noInfiniteScroll: props.apiNoInfiniteScroll,
};

// Vuex (API)
const vuexModule = computed(() => (props.apiVuexProperty ? props.apiVuexProperty.split('/')[0] : null));
const vuexStateProperty = computed(() => (props.apiVuexProperty ? props.apiVuexProperty.split('/')[1] : null));

const currentResponse = ref(null);
const items = props.apiVuexProperty ? computed(() => store.state[vuexModule.value][vuexStateProperty.value]) : ref([]);

// Reactive API query components
const page = ref(1);
const lastPage = ref(1);
const totalItems = ref(null);
const maxItemsAllowed = ref(null);
const { sortedBy, sortOrder } = toRefs(reactive(api));

// Controls whether to replace current array ot to append to it
let replaceItems = true;

// API querystring (depends on limitPerPage, includes, sortedBy, sortOrder and filters)
const queryString = computed(() => {
  const queryBuilder = {
    page: page.value,
    limitPerPage: api.limitPerPage,
    includes: api.includes ?? undefined,
    filters: props.apiFilters ?? undefined,
    sorts: sortedBy.value ? [{ name: sortedBy.value, order: sortOrder.value }] : undefined,
  };
  return queryBuilderToQueryString(queryBuilder);
});

// Whenever filters change, set page to 1 and reset list
watch(
  () => props.apiFilters,
  () => {
    page.value = 1;
    replaceItems = true;
    if (tableEl.value) tableEl.value.scrollTo(0, 0);
  },
  { flush: 'pre', deep: true },
);

// Set items (called after fetch from API)
const setItems = (response) => {
  if (props.apiVuexMutation) store.commit(props.apiVuexMutation, response);
  else items.value = response.data;
};

// Sort (changes reactive query string components)
const sort = (property) => {
  page.value = 1;
  replaceItems = true;
  tableEl.value.scrollTo(0, 0);

  if (sortedBy.value === property) {
    sortOrder.value = sortOrder.value === 'desc' ? 'asc' : 'desc';
  } else {
    sortedBy.value = property;
    sortOrder.value = 'desc';
  }

  emit('sort', sortedBy.value, sortOrder.value);
};

// Increment page
const incrementPage = () => {
  replaceItems = false;
  page.value++;
};

// Infinite scroll
const InfiniteScrollRef = ref(null);
useIntersectionObserver(InfiniteScrollRef, ([{ isIntersecting }]) => {
  if (!isIntersecting) return;
  if (api.limitPerPage == -1) return;
  if (lastPage.value && page.value >= lastPage.value) return;
  if (items.value.length === maxItemsAllowed.value) return;
  incrementPage();
});

// Fetch items
const fetchItems = () => {
  loading.value = true;

  // Axios
  axios
    .get(`${props.apiEndpoint}?${queryString.value}`)
    .then((response) => {
      currentResponse.value = response.data;

      lastPage.value = response.data.last_page;
      totalItems.value = response.data.total;
      if (response.data.max_items_allowed) maxItemsAllowed.value = response.data.max_items_allowed;

      if (replaceItems) setItems(response.data);
      else setItems(items.value ? { ...response.data, data: items.value.concat(response.data.data) } : response.data);

      if (replaceItems) emit('items', response.data.data);
      else emit('items', items.value ? items.value.concat(response.data.data) : response.data);
    })
    .catch((error) => {
      if (!error.response) Sentry.captureException(error);
      if (error.response?.status === 400) toast({ icon: 'warning', title: error.response.data.message });
      if (import.meta.env.VITE_APP_ENV === 'local') console.log(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

// Fetch data when 'queryString' computed property changes
watch(queryString, fetchItems, { flush: 'post', immediate: true });

// Fetch data when refreshTrigger changes
watch(api.refreshTrigger, () => {
  if (api.limitPerPage != -1) page.value = 1;
  replaceItems = true;
  fetchItems();
});

defineExpose({
  items,
  currentResponse,
});
</script>

<style lang="scss">
$tdPaddingX: rem(7.5px);
$tbodyPaddingX: rem(13px);
$rowBorderBottom: rem(1px) solid #ccc;

.table-list {
  overflow: auto;

  // Layout
  &__thead,
  &__tr {
    display: grid;
  }

  // thead
  position: relative;

  &__thead {
    top: 0;
    left: 0;
    position: sticky;
    z-index: 1;
    padding: rem(13px) $tbodyPaddingX;
    padding-bottom: rem(6px);
    background-color: white;
  }

  // tbody
  &__tbody {
    padding: 0 $tbodyPaddingX;
  }

  // tbody (empty)
  &__tbody--empty {
    text-align: center;
    padding: rem(40px) 0;
  }

  // th
  &__th {
    padding: 0 $tdPaddingX;
    color: #999;
    font-size: rem(12px);
    line-height: rem(14px);
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &--sortable {
      cursor: pointer;
    }

    &--sorted {
      color: $aqua;
    }

    .icon {
      font-size: rem(14px);
      margin-right: rem(5px);
    }
  }

  // tr
  &__tr {
    &:not(:last-child) {
      border-bottom: $rowBorderBottom;
    }

    &--clickable {
      cursor: pointer;
    }
  }

  // td
  &__td {
    height: rem(54px);
    display: flex;
    align-items: center;
    font-size: rem(16px);
    overflow: hidden;
    padding: 0 $tdPaddingX;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &-text,
    & > * {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  // Alert
  .alert {
    margin: 0.5em 0;
  }
}

// Theme material
.table-list--material {
  @include box-shadow-light;

  background-color: white;

  .table-list__thead {
    background-color: white;
    border-bottom: rem(1px) solid color('gray', 3);
  }
}

// Theme embedded
.table-list--embedded {
  .table-list__thead {
    padding-left: 0;
    padding-right: 0;
    border-bottom: $rowBorderBottom;
  }

  .table-list__tbody {
    padding-left: 0;
    padding-right: 0;
  }

  .table-list__tr {
    border-bottom: $rowBorderBottom;
  }
}

// Responsive
@media screen and (max-width: 2000px) {
  .table-list {
    &__th {
      padding: 0 rem(5px);
    }

    &__td {
      padding: rem(11px) rem(5px);
    }
  }
}
</style>
