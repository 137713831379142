<template>
  <div v-if="me.permissions['view-reports']" class="sidebar-left__section">
    <!-- Main link -->
    <a class="sidebar-left__link" @click="isExpanded = !isExpanded">
      <SvgIcon name="clipboard" />
      Reports
    </a>

    <!-- Slidebable menu -->
    <div v-if="isExpanded" ref="linksEl" class="sidebar-left__section-links">
      <router-link to="/reports/chart" class="sidebar-left__section-link"> Chart </router-link>

      <router-link to="/reports/cancellations" class="sidebar-left__section-link">
        Cancellations<br />report
      </router-link>

      <router-link to="/reports/expirations" class="sidebar-left__section-link"> Expirations report </router-link>

      <router-link to="/reports/renewal-status" class="sidebar-left__section-link"> Renewal status </router-link>

      <router-link to="/reports/daily" class="sidebar-left__section-link"> Daily report </router-link>

      <!--
      <router-link to="/reports/activity-logs" class="sidebar-left__section-link">
        Activity logs<br />report
      </router-link>
      -->

      <router-link to="/reports/management" class="sidebar-left__section-link"> Management </router-link>

      <router-link to="/reports/email" class="sidebar-left__section-link"> Email </router-link>

      <router-link
        v-if="me.permissions['view-clients-handled-report']"
        to="/reports/clients-handled"
        class="sidebar-left__section-link"
      >
        Clients handled
      </router-link>

      <router-link
        v-if="me.permissions['inforce_policies_report.view']"
        :to="{ name: 'ReportInforcePolicies' }"
        class="sidebar-left__section-link"
      >
        Inforce policies
      </router-link>

      <router-link
        v-if="me.permissions['inforce_policies_report_with_premium.view']"
        :to="{ name: 'ReportInforcePoliciesWithPremium' }"
        class="sidebar-left__section-link"
      >
        Inforce policies with premium
      </router-link>

      <router-link
        v-if="me.permissions['production_report.view']"
        :to="{ name: 'ReportProduction' }"
        class="sidebar-left__section-link"
      >
        Production
      </router-link>

      <router-link
        v-if="me.permissions['prospects_report.view']"
        :to="{ name: 'ReportProspects' }"
        class="sidebar-left__section-link"
      >
        Prospects
      </router-link>

      <router-link
        v-if="me.permissions['non_renewed_report.view']"
        :to="{ name: 'ReportAccountsPoliciesLost' }"
        class="sidebar-left__section-link"
      >
        Final cancel / Non renewed
      </router-link>

      <router-link
        v-if="me.permissions['policies_with_no_dates_report.view']"
        :to="{ name: 'ReportPoliciesWithNoDates' }"
        class="sidebar-left__section-link"
      >
        Policies with no dates
      </router-link>

      <router-link
        v-if="me.permissions['payments.index']"
        :to="{ name: 'ReportAccountingPayments' }"
        class="sidebar-left__section-link"
      >
        Payments
      </router-link>

      <router-link
        v-if="me.permissions['invoices.index']"
        :to="{ name: 'ReportAccountingInvoices' }"
        class="sidebar-left__section-link"
      >
        Invoices
      </router-link>

      <router-link
        v-if="me.permissions['ledger_entries.index']"
        :to="{ name: 'ReportAccountingLedgerEntries' }"
        class="sidebar-left__section-link"
      >
        Ledger Entries
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { me } from '@/js/Helpers/Session';
import SvgIcon from '@/js/Components/SvgIcon.vue';

// Sliding
const isExpanded = ref(false);

const route = useRoute();
const router = useRouter();

// Is reports page
const isReportsPage = (path) => /^\/reports\//.test(path);

// Open menu if visiting directly or refreshing a reports page
if (isReportsPage(route.fullPath))
  onMounted(() => {
    isExpanded.value = !isExpanded.value;
  });

// Collapse menu if user is navigating away from reports
watch(
  () => route.fullPath,
  () => {
    const isNextPageReportsPage = isReportsPage(route.fullPath);
    const wasPreviousPageReportsPage = isReportsPage(router.options.history.state.back);

    if (isExpanded.value && !isNextPageReportsPage && wasPreviousPageReportsPage) isExpanded.value = !isExpanded.value;
  },
);
</script>
