<template>
  <div id="app">
    <component :is="layout" v-if="layout">
      <router-view :key="renderKey"></router-view>
    </component>

    <!-- <MailboxCompose v-if="active" /> -->
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
// import { useGetters, useActions } from 'vuex-composition-helpers';

// Layouts
import LayoutDefault from './LayoutDefault.vue';
// import LayoutMailbox from './LayoutMailbox.vue';
import LayoutCertificateTemplate from './LayoutCertificateTemplate.vue';
import DefaultLayout from './DefaultLayout.vue';

export default {
  components: {
    LayoutDefault,
    DefaultLayout,
    // LayoutMailbox,
    LayoutCertificateTemplate,
    // MailboxCompose: () => import('@/js/Components/Mailbox/MailboxCompose.vue'),
  },

  setup() {
    const route = useRoute();

    // We want to rerender client page when client id changes
    const renderKey = computed(() => (/^\/clients\/\d+/.test(route.path) ? route.path : 1));

    // Get the route layout (wait for route to be resolved first)
    const layout = computed(() => (route.matched.length ? route.meta.layout ?? 'LayoutDefault' : null));

    // const { active } = useGetters({
    //   active: 'draft/active',
    // });

    // const { fetchMailboxAccounts } = useActions({
    //   fetchMailboxAccounts: 'mailbox/fetch',
    // });

    // fetchMailboxAccounts();

    return {
      layout,
      renderKey,
    };
  },
};
</script>
