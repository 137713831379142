import { App } from 'vue';
import { Router } from 'vue-router';
import { User } from '@/js/Api/User';
import { AxiosError, AxiosStatic } from 'axios';
import * as Sentry from '@sentry/vue';
import { BaseTransportOptions, Transport, TransportRequest, Event, EventHint } from '@sentry/types';
import { createTransport } from '@sentry/core';

export const setSentryConfig = (app: App, user: User, axios: AxiosStatic, router: Router) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      Sentry.captureException(error);
      return Promise.reject(error);
    },
  );

  function addAxiosContext(event: Event, error: AxiosError) {
    if (error?.request?.__sentry_xhr_v3__) {
      const contexts = { ...event.contexts };
      contexts.Axios = error.request.__sentry_xhr_v3__;
      event.contexts = contexts;
    }
  }

  function addAxiosContextRecursive(event: Event, error: unknown) {
    if (axios.isAxiosError(error)) {
      addAxiosContext(event, error);
    } else if (error instanceof Error && error.cause) {
      addAxiosContextRecursive(event, error.cause);
    }
  }

  function handleSentryBeforeSend(event: Event, hint: EventHint) {
    addAxiosContextRecursive(event, hint?.originalException);
    return event;
  }

  // Custom sending logic
  function makeAxiosTransport(options: BaseTransportOptions): Transport {
    async function makeRequest(request: TransportRequest) {
      return axios.post(options.url, request.body).then((response) => ({
        headers: {
          'x-sentry-rate-limits': response.headers.get('X-Sentry-Rate-Limits'),
          'retry-after': response.headers.get('Retry-After'),
        },
      }));
    }

    // `createTransport` takes care of rate limiting and flushing
    return createTransport(options, makeRequest);
  }

  // Initialize Sentry
  Sentry.init({
    app,
    enabled: import.meta.env.PROD,
    tunnel: '/sentry/tunnel',
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
    dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    // debug: !import.meta.env.PROD,
    maxBreadcrumbs: 2,
    attachStacktrace: true,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'coveragesco.test', 'coverages.co', /^\//],
      }),
    ],
    // trackComponents: false,
    initialScope: (scope) => {
      scope.setUser({ id: user.id, name: user.name, email: user.email, roles: user.roles });

      return scope;
    },

    // We have to have custom transort, to use axios which has Laravel's CSRF token
    transport: makeAxiosTransport,

    // Attach the POST body
    beforeSend: handleSentryBeforeSend,
  });
};

export default {
  setSentryConfig,
};
