import { z } from 'zod';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import { queryBuilderToQueryString } from '@/js/Helpers';

export const AccountSchema = z.object({
  id: z.number(),
  code: z.number().int(),
  name: z.string(),
  type: z.string(),
  sub_type: z.string(),
  parent_id: z.number().int().nullable(),
  balance_pending: z.string().optional(),
  balance_posted: z.string().optional(),
});

export type Account = z.infer<typeof AccountSchema>;

type AccountPayload = {
  id: number;
  form: Partial<Account>;
  params: Record<string, any>;
};

async function index({ params }: Pick<AccountPayload, 'params'>) {
  const response = await axios.get(`/api/accounts?${queryBuilderToQueryString(params)}`).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: z.object({
        data: z.array(AccountSchema),
      }),
    })
    .parseAsync(response);
}

async function create({ form }: Pick<AccountPayload, 'form'>) {
  const response = await axios.post(`/api/accounts`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: AccountSchema,
    })
    .parseAsync(response.data);
}

async function find({ id }: Pick<AccountPayload, 'id'>) {
  const response = await axios.get(`/api/accounts/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: AccountSchema,
    })
    .parseAsync(response.data);
}

async function update({ id, form }: Omit<AccountPayload, 'params'>) {
  const response = await axios.put(`/api/accounts/${id}`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: AccountSchema,
    })
    .parseAsync(response.data);
}

async function trash({ id }: Pick<AccountPayload, 'id'>) {
  const response = await axios.delete(`/api/accounts/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return response.status === 204;
}
export default {
  index,
  create,
  find,
  update,
  trash,
};
