<template>
  <div v-if="hasAtleastOnePermission" class="sidebar-left__section">
    <!-- Main link -->
    <a class="sidebar-left__link" @click="isExpanded = !isExpanded">
      <SvgIcon name="gear" />
      Settings
    </a>

    <!-- Slidebable menu -->
    <div v-if="isExpanded" class="sidebar-left__section-links">
      <router-link v-if="permissions['edit-global-settings']" to="/settings/global" class="sidebar-left__section-link">
        General
      </router-link>

      <!-- Applications -->
      <router-link class="sidebar-left__section-link" :to="{ name: 'SettingsApplications' }">
        Applications
      </router-link>

      <router-link
        v-if="permissions['edit-team-view-settings']"
        to="/settings/teamview"
        class="sidebar-left__section-link"
      >
        Team View
      </router-link>

      <router-link v-if="permissions['settings_users.page']" to="/settings/users" class="sidebar-left__section-link">
        Users
      </router-link>

      <router-link
        v-if="permissions['view-external-producers']"
        to="/settings/external-producers"
        class="sidebar-left__section-link"
      >
        External<br />producers
      </router-link>

      <router-link
        v-if="me.permissions['invoice_fee_types.index']"
        to="/settings/invoice-fee-types"
        class="sidebar-left__section-link"
      >
        Invoice Fee Types
      </router-link>

      <router-link
        v-if="permissions['finance_companies.index']"
        to="/settings/finance-companies"
        class="sidebar-left__section-link"
      >
        Finance companies
      </router-link>

      <router-link
        v-if="permissions['settings_customer_service_teams.page']"
        to="/settings/csteams"
        class="sidebar-left__section-link"
      >
        Customer<br />service teams
      </router-link>

      <router-link
        v-if="permissions['view-referral-sources']"
        to="/settings/referral-sources"
        class="sidebar-left__section-link"
      >
        Referral Sources
      </router-link>

      <router-link
        v-if="permissions['view-ldl']"
        to="/settings/lead-distribution-lists"
        class="sidebar-left__section-link"
      >
        Lead Distribution Lists
      </router-link>

      <router-link
        v-if="permissions['settings_quote_teams.page']"
        to="/settings/quote-teams"
        class="sidebar-left__section-link"
      >
        Quote Teams
      </router-link>

      <router-link
        v-if="permissions['settings_holders.page']"
        to="/settings/holders"
        class="sidebar-left__section-link"
      >
        Certificate Holders
      </router-link>

      <router-link
        v-if="permissions['settings_descriptions_of_operations.page']"
        to="/settings/descriptions-of-operations"
        class="sidebar-left__section-link"
      >
        Descriptions of Operations
      </router-link>

      <router-link
        v-if="permissions['settings_mappable_fields.page']"
        to="/settings/mappable-fields"
        class="sidebar-left__section-link"
      >
        Mappable fields
      </router-link>

      <router-link
        v-if="permissions['view-mailbox-accounts']"
        to="/settings/mailbox-accounts"
        class="sidebar-left__section-link"
      >
        Mailbox Accounts
      </router-link>

      <router-link
        v-if="permissions['settings_smart_messages.page']"
        to="/settings/smart-messages"
        class="sidebar-left__section-link"
      >
        Smart Messages
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { me } from '@/js/Helpers/Session';
import SvgIcon from '@/js/Components/SvgIcon.vue';

// Sliding
const isExpanded = ref(false);

const route = useRoute();
const router = useRouter();

// Is settings page
const isSettingsPage = (path) => /^\/settings\//.test(path);

// Open menu if visiting directly or refreshing a settings page
if (isSettingsPage(route.fullPath))
  onMounted(() => {
    isExpanded.value = !isExpanded.value;
  });

// Collapse menu if user is navigating away from settings
watch(
  () => route.fullPath,
  () => {
    const isNextPageSettingsPage = isSettingsPage(route.fullPath);
    const wasPreviousPageSettingsPage = isSettingsPage(router.options.history.state.back);

    if (isExpanded.value && !isNextPageSettingsPage && wasPreviousPageSettingsPage)
      isExpanded.value = !isExpanded.value;
  },
);

// Permissions
const permissions = computed(() => ({
  'edit-global-settings': me.permissions['edit-global-settings'],
  'edit-team-view-settings': me.permissions['edit-team-view-settings'],
  'settings_users.page': me.permissions['settings_users.page'],
  'view-external-producers': me.permissions['view-external-producers'],
  'finance_companies.index': me.permissions['finance_companies.index'],
  'settings_customer_service_teams.page': me.permissions['settings_customer_service_teams.page'],
  'view-referral-sources': me.permissions['view-referral-sources'],
  'view-ldl': me.permissions['view-ldl'],
  'settings_quote_teams.page': me.permissions['settings_quote_teams.page'],
  'settings_holders.page': me.permissions['settings_holders.page'],
  'settings_descriptions_of_operations.page': me.permissions['settings_descriptions_of_operations.page'],
  'view-mailbox-accounts': me.permissions['view-mailbox-accounts'],
  'settings_smart_messages.page': me.permissions['settings_smart_messages.page'],
  'settings_mappable_fields.page': me.permissions['settings_mappable_fields.page'],
}));

const hasAtleastOnePermission = computed(() => Object.values(permissions.value).includes(true));
</script>
