<template>
  <IMaskComponent
    :value="localValue"
    mask="num%"
    type="text"
    :lazy="false"
    :eager="true"
    :blocks="{
      num: {
        mask: Number,
        scale: 3,
        radix: '.',
        mapToRadix: ['.'],
      },
    }"
    @blur="handleBlur"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import numeral from 'numeral';
import { IMaskComponent } from 'vue-imask';

type PercentageInputProps = {
  modelValue: string | number | null;
};

const props = defineProps<PercentageInputProps>();

const emit = defineEmits(['update:modelValue']);

const localValue = computed(() => {
  return numeral(props.modelValue ?? 0)
    .divide(100)
    .format('0.[000]%');
});

const handleBlur = (event: FocusEvent) => {
  const inputElement = event.target as HTMLInputElement;
  let value = inputElement.value.trim();

  // Remove the '%' suffix if present
  if (value.endsWith('%')) {
    value = value.slice(0, -1);
  }

  // If the input is empty after removing '%', default to '0'
  if (value === '') {
    emit('update:modelValue', '0');
    return;
  }

  // Attempt to parse the numerical part
  const number = Number(value);

  // If parsing fails (NaN), default to '0' or handle as needed
  if (Number.isNaN(number)) {
    emit('update:modelValue', '0');
    return;
  }

  // Emit the validated and scaled value as a string
  emit('update:modelValue', number.toString());
};
</script>
