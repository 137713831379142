import { createRouter, createWebHistory } from 'vue-router';
import useGlobalStore from '@/js/store/pinia/useGlobalStore';

// Client
import Client from '@/js/Pages/Client/Client.vue';
import ClientMainView from '@/js/Pages/Client/MainView/MainView.vue';
import ClientEditView from '@/js/Pages/Client/EditView/EditView.vue';
import ClientAttachments from '@/js/Pages/Client/Attachments.vue';
import ClientQuoteGroupCreate from '@/js/Pages/Client/QuoteGroups/Create.vue';
import ClientQuoteGroupEdit from '@/js/Pages/Client/QuoteGroupEdit/QuoteGroupEdit.vue';

// Policy
import Policy from '@/js/Components/Policies/PolicyMain.vue';
import ClientPolicyShow from '@/js/Pages/Client/Policies/Show.vue';
import ClientPolicyCreate from '@/js/Pages/Client/Policies/Create.vue';
import PoliciesPendingApproval from '@/js/Pages/PendingApproval/Policies.vue';
import ClientPolicyProcessingMain from '@/js/Pages/Client/Policies/Processing/ProcessingMain.vue';

// Dashboard
import Dashboard from '@/js/Pages/Dashboard/Dashboard.vue';

const routes = [
  // Client
  {
    path: '/clients/:clientId',
    name: 'Client',
    component: Client,
    props: true,
    children: [
      {
        path: '',
        component: ClientMainView,
        name: 'ClientMainView',
      },

      {
        path: 'edit',
        component: ClientEditView,
        name: 'ClientEditView',
      },

      {
        path: 'attachments',
        component: ClientAttachments,
        name: 'ClientAttachments',
      },

      {
        path: 'quotegroups/create',
        component: ClientQuoteGroupCreate,
        name: 'ClientQuoteGroupCreate',
      },

      {
        path: 'quotegroups/:quoteGroupId/edit',
        component: ClientQuoteGroupEdit,
        name: 'ClientQuoteGroupEdit',
        props: true,
      },

      {
        path: 'policies/create',
        component: ClientPolicyCreate,
        name: 'ClientPolicyCreate',
      },

      {
        path: 'policies/:policyId',
        name: 'ClientPolicy',
        component: ClientPolicyShow,
        children: [
          { path: '', component: Policy, name: 'ClientPolicyMain' },
          { path: 'processing', component: ClientPolicyProcessingMain, name: 'ClientPolicyProcessingMain' },
        ],
        props: true,
      },

      {
        path: 'invoices',
        component: () => import('@/js/Pages/Client/Invoices/InvoicesTable.vue'),
        name: 'ClientInvoices',
        props: true,
      },

      {
        path: 'invoices/:invoiceId',
        component: () => import('@/js/Pages/Client/Invoices/Invoice.vue'),
        name: 'ClientInvoice',
        props: (route) => {
          /**
           * This would preserve the other route.params object properties overriding only
           * `userId` in case it exists with its integer equivalent, or otherwise with
           * undefined.
           */
          return { ...route.params, ...{ invoiceId: Number.parseInt(route.params.invoiceId, 10) || undefined } };
        },
      },

      {
        path: 'payments',
        component: () => import('@/js/Pages/Client/Payments/PaymentsTable.vue'),
        name: 'ClientPayments',
        props: true,
      },

      {
        path: 'certificates',
        name: 'ClientCertificatesPanels',
        component: () => import('@/js/Pages/Client/Certificates/Panels.vue'),
      },
      {
        path: 'certificates/:templateId',
        component: () => import('@/js/Pages/Client/Certificates/Template.vue'),
        name: 'ClientCertificatesTemplate',
        meta: { layout: 'LayoutCertificateTemplate' },
        props: true,
      },

      {
        path: 'applications',
        component: () => import('@/js/Pages/Client/Applications.vue'),
        name: 'ClientApplications',
        props: true,
      },

      {
        path: 'vehicles-and-drivers',
        component: () => import('@/js/Pages/Client/VehiclesAndDrivers.vue'),
        name: 'ClientVehiclesAndDrivers',
        props: true,
      },
    ],
  },

  // Dashboard
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },

  // Pending approval
  {
    path: '/certificates/pending-approval',
    name: 'CertificatesPendingApproval',
    component: () => import('@/js/Pages/PendingApproval/Certificates.vue'),
  },
  { path: '/policies/pending-approval', name: 'PoliciesPendingApproval', component: PoliciesPendingApproval },

  // Settings
  { path: '/settings/global', name: 'SettingsGlobal', component: () => import('@/js/Pages/Settings/Global/Main.vue') },
  {
    path: '/settings/teamview',
    name: 'SettingsTeamView',
    component: () => import('@/js/Pages/Settings/TeamView/TeamView.vue'),
  },
  { path: '/settings/users', name: 'SettingsUsers', component: () => import('@/js/Pages/Settings/Users.vue') },
  {
    path: '/settings/external-producers',
    name: 'SettingsExternalProducers',
    component: () => import('@/js/Pages/Settings/ExternalProducers/ExternalProducers.vue'),
  },
  {
    path: '/settings/holders',
    name: 'SettingsHolders',
    component: () => import('@/js/Pages/Settings/Holders/Holders.vue'),
  },
  {
    path: '/settings/invoice-fee-types',
    name: 'InvoiceFeeTypes',
    component: () => import('@/js/Pages/Settings/InvoiceFeeTypes.vue'),
    meta: { layout: 'DefaultLayout' },
  },
  {
    path: '/settings/descriptions-of-operations',
    name: 'SettingsDOOs',
    component: () => import('@/js/Pages/Settings/DOOs/DOOs.vue'),
  },
  {
    path: '/settings/csteams',
    name: 'SettingsCST',
    component: () => import('@/js/Pages/Settings/CST/SettingsCST.vue'),
  },
  {
    path: '/settings/referral-sources',
    name: 'SettingsReferralSources',
    component: () => import('@/js/Pages/Settings/ReferralSources/Main.vue'),
  },
  {
    path: '/settings/quote-teams',
    name: 'SettingsQuoteTeams',
    component: () => import('@/js/Components/QuoteTeams/Page.vue'),
  },
  {
    path: '/settings/finance-companies',
    name: 'SettingsFinanceCompanies',
    component: () => import('@/js/Pages/Settings/FinanceCompanies.vue'),
  },
  {
    path: '/settings/lead-distribution-lists',
    name: 'SettingsLeadDistributionLists',
    component: () => import('@/js/Pages/Settings/LeadDistributionLists/Main.vue'),
  },
  {
    path: '/settings/mappable-fields',
    name: 'SettingsMappableFields',
    component: () => import('@/js/Pages/Settings/MappableFields.vue'),
  },
  // {
  //   path: '/settings/mailbox-accounts',
  //   name: 'SettingsMailboxAccount',
  //   component: () => import('@/js/Pages/Settings/MailboxAccounts/MailboxAccounts.vue'),
  // },
  {
    path: '/settings/smart-messages',
    name: 'SettingsSmartMessages',
    component: () => import('@/js/Pages/Settings/SmartMessages/SmartMessages.vue'),
  },
  {
    path: '/settings/email-templates',
    name: 'SettingsEmailTemplates',
    component: () => import('@/js/Pages/Settings/EmailTemplates/EmailTemplates.vue'),
  },
  {
    path: '/settings/merge-clients',
    name: 'SettingsMergeClients',
    component: () => import('@/js/Pages/Settings/MergeClients/MergeClients.vue'),
  },
  {
    path: '/settings/merged-clients-producers',
    name: 'SettingsMergedClientsProducers',
    component: () => import('@/js/Pages/Settings/MergedClientsProducers/MergedClientsProducers.vue'),
  },
  {
    path: '/settings/applications',
    name: 'SettingsApplications',
    component: () => import('@/js/Pages/Settings/Applications.vue'),
  },

  // Accounting
  {
    path: '/accounting',
    meta: { layout: 'DefaultLayout' },
    children: [
      {
        path: 'dashboard',
        name: 'AccountingDashboard',
        component: () => import('@/js/Pages/Accounting/Dashboard.vue'),
      },
      {
        path: 'chart-of-accounts',
        name: 'AccountingChartOfAccounts',
        component: () => import('@/js/Pages/Accounting/ChartOfAccounts.vue'),
      },
      {
        path: 'manual-ledger-entries',
        name: 'AccountingManualLedgerEntries',
        component: () => import('@/js/Pages/Accounting/ManualLedgerEntries.vue'),
      },
    ],
  },

  // Directory
  {
    path: '/directory/agencies',
    name: 'DirectoryAgencies',
    component: () => import('@/js/Pages/Settings/Agencies.vue'),
  },
  {
    path: '/directory/carriers',
    name: 'DirectoryCarriers',
    component: () => import('@/js/Pages/Settings/Carriers.vue'),
  },

  // Search
  { path: '/search/policies', name: 'SearchPolicies', component: () => import('@/js/Pages/Search/Policies/Main.vue') },

  // Mailbox
  // {
  //   path: '/mailbox',
  //   name: 'Mailbox',
  //   component: () => import('@/js/Pages/Mailbox/Mailbox.vue'),
  //   meta: { layout: 'LayoutMailbox' },
  // },

  // Reports
  {
    path: '/reports/chart',
    name: 'ReportChart',
    component: () => import('@/js/Pages/Reports/Chart/Main.vue'),
  },
  {
    path: '/reports/cancellations',
    name: 'ReportCancellations',
    component: () => import('@/js/Pages/Reports/Cancellations/Main.vue'),
  },
  {
    path: '/reports/expirations',
    name: 'ReportExpirations',
    component: () => import('@/js/Pages/Reports/Expirations/Main.vue'),
  },
  {
    path: '/reports/renewal-status',
    name: 'ReportRenewalStatus',
    component: () => import('@/js/Pages/Reports/RenewalStatus/Main.vue'),
  },
  // { path: '/reports/activity-logs', name: 'ReportActivityLogs', component: ReportActivityLogs },
  {
    path: '/reports/daily',
    name: 'ReportDaily',
    component: () => import('@/js/Pages/Reports/Daily/Main.vue'),
  },
  {
    path: '/reports/management',
    name: 'ReportManagement',
    component: () => import('@/js/Pages/Reports/Management/Main.vue'),
  },
  {
    path: '/reports/email',
    name: 'ReportEmail',
    component: () => import('@/js/Pages/Reports/Email/ReportsEmail.vue'),
  },
  {
    path: '/reports/clients-handled',
    name: 'ReportClientsHandled',
    component: () => import('@/js/Pages/Reports/ReportClientsHandled/ReportClientsHandled.vue'),
  },
  {
    path: '/reports/inforce-policies-with-premium',
    name: 'ReportInforcePoliciesWithPremium',
    component: () => import('@/js/Pages/Reports/InforcePoliciesWithPremium/Main.vue'),
  },
  {
    path: '/reports/inforce-policies',
    name: 'ReportInforcePolicies',
    component: () => import('@/js/Pages/Reports/InforcePolicies/Main.vue'),
  },
  {
    path: '/reports/production',
    name: 'ReportProduction',
    component: () => import('@/js/Pages/Reports/Production/Main.vue'),
  },
  {
    path: '/reports/prospects',
    name: 'ReportProspects',
    component: () => import('@/js/Pages/Reports/Prospects/Main.vue'),
  },
  {
    path: '/reports/non-renewed',
    name: 'ReportAccountsPoliciesLost',
    component: () => import('@/js/Pages/Reports/NonRenewed/Main.vue'),
  },
  {
    path: '/reports/accounting/payments',
    name: 'ReportAccountingPayments',
    component: () => import('@/js/Pages/Reports/Accounting/Payments.vue'),
    meta: { layout: 'DefaultLayout' },
  },
  {
    path: '/reports/accounting/invoices',
    name: 'ReportAccountingInvoices',
    component: () => import('@/js/Pages/Reports/Accounting/Invoices.vue'),
    meta: { layout: 'DefaultLayout' },
  },
  {
    path: '/reports/accounting/ledger-entries',
    name: 'ReportAccountingLedgerEntries',
    component: () => import('@/js/Pages/Reports/Accounting/LedgerEntries.vue'),
    meta: { layout: 'DefaultLayout' },
  },
  {
    path: '/reports/policies-with-no-dates',
    name: 'ReportPoliciesWithNoDates',
    component: () => import('@/js/Pages/Reports/ReportPoliciesWithNoDates/Main.vue'),
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
});

router.beforeEach(async () => {
  const globalStore = useGlobalStore();
  await globalStore.processingPromise;
});

export default router;
