import axios, { AxiosError } from 'axios';
import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import { PARENT_RENEWAL_STATUS } from '@/js/Presenters/Policy';
import { DateRange } from '@/js/Components/TableListFiltersDateRange.vue';
import { Option as ProducerOption } from '@/js/Components/Producers/Filter.vue';
import { queryBuilderToQueryString } from '@/js/Helpers';
import { IntakeSchema } from './Intake';
import { CarrierSchema } from './Carrier';
import { AgencySchema } from './Agency';
import { FinanceCompanySchema } from './FinanceCompany';
import { PolicyCancellationSchema } from './PolicyCancellation';
import { UserSchema } from './User';

export const STATUSES = [
  'Active',
  'Expired',
  'Cancelled',
  'Cancellation pending',
  'Pending',
  'Renewal (unpaid)',
] as const;

export const PARENT_RENEWAL_STATUSES = ['Rewrite', 'Renewal (paid)', 'Renewal (unpaid)', 'New business'] as const;

export const CHILD_RENEWAL_STATUSES = ['Rewritten', 'Renewed (paid)', 'Renewed (unpaid)'] as const;

export const BILLING_TYPES = ['Direct Bill', 'Agency Bill - PIF', 'Agency Bill - Financed'] as const;

const BasePolicySchema = z.object({
  id: z.number(),
  producer_id: z.number().nullable(),
  quoting_specialist_id: z.number().nullable(),
  customer_service_id: z.number().nullable(),
  customer_service_type: z.string().nullable(),
  client_id: z.number(),
  quote_id: z.number().nullable(),
  agency_id: z.number().nullable(),
  carrier_id: z.number().nullable(),
  number: z.string().nullable(),
  premium: z.number().nullable(),
  remarks: z.string().nullable(),
  label: z.string().nullable(),
  effective_date: z.string().nullable(),
  expiration_date: z.string().nullable(),
  renewal_status_as_parent: z.enum(PARENT_RENEWAL_STATUSES).nullable(),
  renewal_status_as_child: z.enum(CHILD_RENEWAL_STATUSES).nullable(),
  non_renewed: z.boolean().nullable(),
  non_renewed_requested: z.boolean().nullable(),
  non_renewed_requested_on: z.string().nullable(),
  non_renewed_requested_by: z.number().nullable(),
  non_renewed_approved_on: z.string().nullable(),
  non_renewed_approved_by: z.number().nullable(),
  last_verified_on: z.string(),
  last_verified_by: z.number(),
  billing_type: z.enum(BILLING_TYPES).nullable(),
  finance_company_id: z.number().nullable(),
  finance_account_number: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.string().nullable(),

  // Appends
  status: z.enum(STATUSES),
  model_type: z.literal('Policy'),

  // Relations
  agency: AgencySchema.nullish(),
  carrier: CarrierSchema.nullish(),
  finance_company: FinanceCompanySchema.nullish(),
  intakes: z.array(IntakeSchema).optional(),
  last_verified_by_user: UserSchema.nullish(),
  cancellation: PolicyCancellationSchema.nullish(),
  cancellations: z.array(PolicyCancellationSchema).nullish(),
  non_renewed_requested_by_user: UserSchema.nullish(),
  non_renewed_approved_by_user: UserSchema.nullish(),
});

export type Policy = z.infer<typeof BasePolicySchema> & {
  renewed_by_policy?: Policy | null;
  renews_policies?: Policy[];
};

export const PolicySchema: z.ZodType<Policy> = BasePolicySchema.extend({
  renewed_by_policy: z.lazy(() => PolicySchema.nullish()),
  renews_policies: z.lazy(() => PolicySchema.array().optional()),
});

// ***************************************************
// Index
// ***************************************************

export type IndexArgs = {
  queryBuilder: {
    sorts?: {
      order: 'desc' | 'asc';
      name: 'active' | 'effective_date' | 'expiration_date' | 'created_at';
    }[];
    includes?: (
      | 'client'
      | 'client.producer'
      | 'carrier'
      | 'agency'
      | 'finance_company'
      | 'intakes'
      | 'intakes.intake_body'
      | 'cancellation'
      | 'cancellations'
      | 'last_verified_by_user'
      | 'renews_policies'
      | 'renewed_by_policy'
      | 'non_renewed_requested_by_user'
    )[];
    filters?: {
      client_id?: number;
      carrier_id?: number[] | number | null;
      agency_id?: number[] | number | null;
      finance_company_id?: number[] | number | null;
      renewal_status_as_parent?: Policy['renewal_status_as_parent'][] | Policy['renewal_status_as_parent'];
      non_renewed?: boolean | null;
      non_renewed_requested?: boolean | null;
      effective_date?: string | null;
      expiration_date?: string | null;
      status?: Policy['status'];
      number?: string | null;
      with_trashed?: true;
      hide_non_renewed?: true;
      hide_rewritten?: true;
      hide_renewed_paid?: true;
      inforce?: true;
      coverage_type?: string[];
      effective_to?: string;
      effective_from?: string;
      effective_between?: [string, string];
      expiration_to?: string;
      expiration_from?: string;
      expired_between?: [string, string];
      expiration_days_backward?: number;
      expiration_days_forward?: number;
      cancellation_days_backward?: number;
      cancellation_days_forward?: number;
      cancelled_between?: [string, string];
      expired_or_cancelled_between?: [string, string];
      created_at_to?: string;
      created_at_from?: string;
      client?: {
        producer?: string[];
        addresses?: {
          province?: string | null;
        };
      };
    };
  };
};

async function index({ queryBuilder }: IndexArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/policies?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return response;
}

// ***************************************************
// All
// ***************************************************

type AllQueryBuilder = {
  sorts?: {
    order: 'desc' | 'asc';
    name: 'status' | 'effective_date' | 'expiration_date' | 'created_at';
  }[];
  includes?: (
    | 'client'
    | 'client.producer'
    | 'carrier'
    | 'agency'
    | 'finance_company'
    | 'intakes'
    | 'intakes.intake_body'
    | 'cancellation'
    | 'cancellations'
    | 'last_verified_by_user'
    | 'renews_policies'
    | 'renewed_by_policy'
  )[];
  filters?: {
    client_id: number;
    with_trashed?: true;
    number?: string | null;
  };
};

async function all({ queryBuilder }: { queryBuilder: AllQueryBuilder }) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios.get(`/api/policies/all?${qs}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: PolicySchema.array(),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Find
// ***************************************************

async function find({ id, clientId }: { id: number; clientId: number }) {
  const response = await axios.get(`/api/clients/${clientId}/policies/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: PolicySchema,
    })
    .parseAsync(response.data);
}

// ***************************************************
// Total premium
// ***************************************************

export type TotalPremiumFilters = {
  agency_id?: number;
  carrier_id?: number;
  inforce?: true;
  coverage_type?: string;
  expired_between?: DateRange;
  cancelled_between?: DateRange;
  renewal_status?: PARENT_RENEWAL_STATUS;
  client?: {
    producer?: string[];
  };
};

async function totalPremium({ filters }: { filters: TotalPremiumFilters }) {
  const qs = queryBuilderToQueryString({ filters });
  const response = await axios.get(`/api/policies/total-premium?${qs}`).catch((error) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: z.number(),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Count
// ***************************************************

export type CountFilters = {
  agency_id?: number;
  carrier_id?: number;
  inforce?: true;
  coverage_type?: string;
  expired_between?: DateRange;
  cancelled_between?: DateRange;
  renewal_status?: PARENT_RENEWAL_STATUS;
  non_renewed_requested?: true;
  client?: {
    producer?: string[];
  };
};

async function count({ filters }: { filters: CountFilters }) {
  const qs = queryBuilderToQueryString({ filters });
  const response = await axios.get(`/api/policies/count?${qs}`).catch((error) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: z.number(),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Dataset
// ***************************************************

type DatasetArgs = {
  groupBy: string;
  interval: string;
  name: string;
  aggFunction: string;
  queryBuilder: {
    filters: {
      carrier_id?: number[] | number | null;
      agency_id?: number[] | number | null;
      finance_company_id?: number[] | number | null;
      renewal_status_as_parent?: Policy['renewal_status_as_parent'][] | Policy['renewal_status_as_parent'];
      coverage_type?: string[] | string;
      client?: {
        producer?: string[] | string;
      };
    };
  };
};

async function datasetEffectiveDate({ groupBy, interval, name, aggFunction, queryBuilder }: DatasetArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios
    .get(
      `/api/policies/dataset/effective_date?group_by=${groupBy}&interval=${interval}&name=${name}&agg_function=${aggFunction}&${qs}`,
    )
    .catch((error: AxiosError) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: z.object({
        name: z.string(),
        dataset: z
          .object({
            date: z.string(),
            metric: z.number(),
          })
          .array(),
      }),
    })
    .parseAsync(response.data);
}

async function datasetInforce({ groupBy, interval, name, aggFunction, queryBuilder }: DatasetArgs) {
  const qs = queryBuilderToQueryString(queryBuilder);
  const response = await axios
    .get(
      `/api/policies/dataset/inforce?group_by=${groupBy}&interval=${interval}&name=${name}&agg_function=${aggFunction}&${qs}`,
    )
    .catch((error: AxiosError) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: z.object({
        name: z.string(),
        dataset: z
          .object({
            date: z.string(),
            metric: z.string().or(z.number()), // Why does MySQL return SUM(premium) as string? It should be integer.
          })
          .array(),
      }),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Create
// ***************************************************

async function create({ clientId, coverageTypeIds }: { clientId: number; coverageTypeIds: number[] }) {
  const response = await axios
    .post(`/api/clients/${clientId}/policies`, {
      coverage_ids: coverageTypeIds,
    })
    .catch((error: AxiosError) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: PolicySchema,
    })
    .parseAsync(response.data);
}

// ***************************************************
// Update
// ***************************************************

export type UpdateArgs = {
  id: number;
  form: Partial<
    Pick<
      Policy,
      | 'producer_id'
      | 'number'
      | 'premium'
      | 'agency_id'
      | 'carrier_id'
      | 'effective_date'
      | 'expiration_date'
      | 'quoting_specialist_id'
      | 'customer_service_id'
      | 'remarks'
      | 'label'
      | 'billing_type'
      | 'finance_company_id'
      | 'finance_account_number'
    >
  >;
};

async function update({ id, form }: UpdateArgs) {
  const response = await axios.put(`/api/policies/${id}`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: PolicySchema.partial(),
    })
    .parseAsync(response.data);
}

// ***************************************************
// Verify status
// ***************************************************

async function verifyStatus({ id, clientId }: { id: number; clientId: number }) {
  const response = await axios.put(`/api/clients/${clientId}/policies/${id}/verify`).catch((error: AxiosError) => {
    Sentry.captureException(error);
    throw error;
  });

  return z
    .object({
      data: PolicySchema,
    })
    .parseAsync(response.data);
}

// ***************************************************
// Update renewal status
// ***************************************************

async function updateRenewalStatus({
  id,
  clientId,
  parentRenewalStatus,
  renewingPolicyIds,
}: {
  id: number;
  clientId: number;
  parentRenewalStatus: PARENT_RENEWAL_STATUS | null;
  renewingPolicyIds?: number[];
}) {
  const response = await axios
    .post(`/api/clients/${clientId}/policies/${id}/renewal-status`, {
      _method: 'PUT',
      parent_renewal_status: parentRenewalStatus,
      renewing_policy_ids: renewingPolicyIds,
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: PolicySchema,
    })
    .parseAsync(response.data);
}

// ***************************************************
// Request Non renewed / final cancel
// ***************************************************

async function requestNonRenewed({ id, clientId }: { id: number; clientId: number }) {
  const response = await axios
    .post(`/api/clients/${clientId}/policies/${id}/non-renewed/request`, {
      _method: 'PUT',
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: PolicySchema,
    })
    .parseAsync(response.data);
}

// ***************************************************
// Approve Non renewed / final cancel
// ***************************************************

async function approveNonRenewed({ id, clientId }: { id: number; clientId: number }) {
  const response = await axios
    .post(`/api/clients/${clientId}/policies/${id}/non-renewed/approve`, {
      _method: 'PUT',
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: PolicySchema,
    })
    .parseAsync(response.data);
}

// ***************************************************
// Disapprove Non renewed / final cancel
// ***************************************************

async function disapproveNonRenewed({ id, clientId }: { id: number; clientId: number }) {
  const response = await axios
    .post(`/api/clients/${clientId}/policies/${id}/non-renewed/disapprove`, {
      _method: 'PUT',
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: PolicySchema,
    })
    .parseAsync(response.data);
}

// ***************************************************
// Unmark Non renewed / final cancel
// ***************************************************

async function unmarkNonRenewed({ id, clientId }: { id: number; clientId: number }) {
  const response = await axios
    .post(`/api/clients/${clientId}/policies/${id}/non-renewed/unmark`, {
      _method: 'PUT',
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });

  return z
    .object({
      data: PolicySchema,
    })
    .parseAsync(response.data);
}

export default {
  index,
  all,
  find,
  totalPremium,
  count,
  datasetEffectiveDate,
  datasetInforce,
  create,
  update,
  verifyStatus,
  updateRenewalStatus,
  requestNonRenewed,
  approveNonRenewed,
  disapproveNonRenewed,
  unmarkNonRenewed,
};
