<template>
  <div v-if="hasAtleastOnePermission" class="sidebar-left__section">
    <!-- Main link -->
    <a class="sidebar-left__link" @click="isExpanded = !isExpanded">
      <SvgIcon name="accounting" />
      Accounting
    </a>

    <!-- Expendable menu -->
    <div v-if="isExpanded" class="sidebar-left__section-links">
      <router-link to="/accounting/dashboard" class="sidebar-left__section-link">Dashboard</router-link>

      <router-link to="/accounting/chart-of-accounts" class="sidebar-left__section-link">
        Chart of Accounts
      </router-link>

      <router-link to="/accounting/manual-ledger-entries" class="sidebar-left__section-link">
        Manual Ledger Entries
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { me } from '@/js/Helpers/Session';
import SvgIcon from '@/js/Components/SvgIcon.vue';

// Sliding
const isExpanded = ref(false);

const route = useRoute();
const router = useRouter();

// Is settings page
const isAccountingPage = (path) => /^\/accounting\//.test(path);

// Open menu if visiting directly or refreshing a settings page
if (isAccountingPage(route.fullPath))
  onMounted(() => {
    isExpanded.value = !isExpanded.value;
  });

// Collapse menu if user is navigating away from settings
watch(
  () => route.fullPath,
  () => {
    const isNextPageSettingsPage = isAccountingPage(route.fullPath);
    const wasPreviousPageSettingsPage = isAccountingPage(router.options.history.state.back);

    if (isExpanded.value && !isNextPageSettingsPage && wasPreviousPageSettingsPage)
      isExpanded.value = !isExpanded.value;
  },
);

// Permissions
const permissions = computed(() => ({
  'edit-global-settings': me.permissions['edit-global-settings'],
  'edit-team-view-settings': me.permissions['edit-team-view-settings'],
  'settings_users.page': me.permissions['settings_users.page'],
  'view-external-producers': me.permissions['view-external-producers'],
  'finance_companies.index': me.permissions['finance_companies.index'],
  'settings_customer_service_teams.page': me.permissions['settings_customer_service_teams.page'],
  'view-referral-sources': me.permissions['view-referral-sources'],
  'view-ldl': me.permissions['view-ldl'],
  'settings_quote_teams.page': me.permissions['settings_quote_teams.page'],
  'settings_holders.page': me.permissions['settings_holders.page'],
  'settings_descriptions_of_operations.page': me.permissions['settings_descriptions_of_operations.page'],
  'view-mailbox-accounts': me.permissions['view-mailbox-accounts'],
  'settings_smart_messages.page': me.permissions['settings_smart_messages.page'],
  'settings_mappable_fields.page': me.permissions['settings_mappable_fields.page'],
  'accounts.index': me.permissions['accounts.index'],
}));

const hasAtleastOnePermission = computed(() => Object.values(permissions.value).includes(true));
</script>
