import { z } from 'zod';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import { queryBuilderToQueryString } from '@/js/Helpers';
import { AccountSchema } from './Account';

export const LedgerEntrySchema = z.object({
  id: z.number(),
  amount: z.number().int(),
  debit_account_id: z.number().int(),
  credit_account_id: z.number().int(),
  status: z.enum(['pending', 'posted', 'void']),
  source: z.enum(['invoice', 'payment', 'manual']),
  invoice_id: z.number().int().nullable(),
  payment_id: z.number().int().nullable(),
  debit_account: AccountSchema.optional(),
  credit_account: AccountSchema.optional(),
});

export type LedgerEntry = z.infer<typeof LedgerEntrySchema>;

type LedgerEntryPayload = {
  id: number;
  form: Partial<LedgerEntry>;
  params: Record<string, any>;
};

async function index({ params }: Pick<LedgerEntryPayload, 'params'>) {
  const response = await axios
    .get(`/api/ledger-entries?${queryBuilderToQueryString(params)}`)
    .catch((error: AxiosError) => {
      Sentry.captureException(error);

      throw error;
    });

  return z.object({ data: LedgerEntrySchema }).parseAsync(response.data);
}

async function create({ form }: Pick<LedgerEntryPayload, 'form'>) {
  const response = await axios.post(`/api/ledger-entries`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: LedgerEntrySchema,
    })
    .parseAsync(response.data);
}

async function find({ id }: Pick<LedgerEntryPayload, 'id'>) {
  const response = await axios.get(`/api/ledger-entries/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: LedgerEntrySchema,
    })
    .parseAsync(response.data);
}

async function update({ id, form }: Omit<LedgerEntryPayload, 'params'>) {
  const response = await axios.put(`/api/ledger-entries/${id}`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: LedgerEntrySchema,
    })
    .parseAsync(response.data);
}

async function trash({ id }: Pick<LedgerEntryPayload, 'id'>) {
  const response = await axios.delete(`/api/ledger-entries/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return response.status === 204;
}

export default {
  index,
  create,
  find,
  update,
  trash,
};
