import { createPinia } from 'pinia';
import router from './router';
import { setZodConfig } from './config/zod';
import { setSentryConfig } from './config/sentry';
import { setChartjsConfig } from './config/chartjs';

import 'virtual:svg-icons-register';

/* CSS */
import '../sass/app.scss';
import '../sass/main.css';

/* FormValidation */

/* Axios */
import axios from 'axios';

/* Sweetalert2 */
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

/* String */
import './Helpers/String';
import './Helpers/Date';
import './Helpers/Number';
import './Helpers/FormData';
import Address from './Helpers/Address';

/* Auto logout */
import './Core/autologout.js';

/* Root */
import { createApp } from 'vue';
import Root from './Layout/Root.vue';

import FormValidation from './Plugins/FormValidation';

import Store from './store/store.js';

const vuePlugins = [];
const vueGlobals = {};
vuePlugins.push(FormValidation);

window.axios = axios;
vueGlobals.axios = axios;

window.Swal = Swal;
window.toast = Swal.mixin({
  customClass: { container: 'swal-toast-container' },
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 4000,
});
window.confirm = Swal.mixin({
  text: 'Are you sure?',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#19497F',
  cancelButtonColor: '#E90001',
  confirmButtonText: 'Yes, delete it!',
});
window.prompt = Swal.mixin({
  text: 'Type in "delete" to confirm.',
  input: 'text',
  showCancelButton: true,
  confirmButtonText: 'Delete',
  confirmButtonColor: '#19497F',
  cancelButtonColor: '#E90001',
});
vueGlobals.$prompt = (options) => {
  return Swal.mixin({
    text: 'Type in "delete" to confirm.',
    input: 'text',
    showCancelButton: true,
    confirmButtonText: 'Delete',
    confirmButtonColor: '#19497F',
    cancelButtonColor: '#E90001',
  }).fire(options);
};
vueGlobals.$toast = (options) => {
  return Swal.mixin({
    customClass: { container: 'swal-toast-container' },
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000,
  }).fire(options);
};
vueGlobals.$confirm = (options) => {
  return Swal.mixin({
    text: 'Are you sure?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#19497F',
    cancelButtonColor: '#E90001',
    confirmButtonText: 'Yes, delete it!',
  }).fire(options);
};
window.popupExists = () => !!document.querySelector('.swal2-center');

// Some more vue globals
vueGlobals.document = document;
vueGlobals.console = console;
vueGlobals.confirm = window.confirm;

// Loader (to be removed)
vueGlobals.$loader = {
  start() {
    document.getElementById('loader').style.display = 'block';
    document.getElementById('app').style.pointerEvents = 'none';
    if ([...document.querySelectorAll('.modal.show')].length) {
      [...document.querySelectorAll('.modal-header, .modal-body')].forEach((el) => {
        el.style.opacity = '0.5';
        el.pointerEvents = 'none';
      });
    } else {
      document.getElementById('app').style.opacity = '0.5';
    }
  },
  end() {
    document.getElementById('loader').style.display = 'none';
    document.getElementById('app').style.pointerEvents = 'auto';
    document.getElementById('app').style.opacity = '1';
    [...document.querySelectorAll('.modal-header, .modal-body')].forEach((el) => {
      el.style.opacity = '1';
      el.pointerEvents = 'auto';
    });
  },
};
vueGlobals.Address = Address;

/* Remove "focused" class */
document.addEventListener('click', () => {
  for (const el of document.querySelectorAll('.focused')) el.classList.remove('focused');
});

/* Sliding */
vueGlobals.$slider = {
  slideToggle(target, callback) {
    if (window.getComputedStyle(target).display === 'none') {
      return this.slideDown(target, callback);
    }
    return this.slideUp(target, callback);
  },
  slideDown(target, callback) {
    this.setup(target);
    target.style.display = 'block';
    target.style.height = 'auto';
    const height = `${target.clientHeight}px`;
    target.style.height = '0px';
    window.setTimeout(() => {
      target.style.height = height;
      target.style.overflow = 'hidden';
    }, 0);
    window.setTimeout(() => {
      target.style.removeProperty('height');
      this.cleanup(target);
      if (typeof callback === 'function') callback();
    }, 350);
  },
  slideUp(target, callback) {
    this.setup(target);
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.style.height = `${target.clientHeight}px`;
    window.setTimeout(() => {
      target.style.height = '0px';
    });
    window.setTimeout(() => {
      target.style.display = 'none';
      target.style.removeProperty('margin-top');
      target.style.removeProperty('margin-bottom');
      target.style.removeProperty('padding-top');
      target.style.removeProperty('padding-bottom');
      this.cleanup(target);
      if (typeof callback === 'function') callback();
    }, 400);
  },
  setup(target) {
    target.style.overflow = 'hidden';
    target.style.transitionDuration = '350ms';
    target.style.transitionProperty = 'height, padding';
  },
  cleanup(target) {
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-property');
    target.style.removeProperty('transition-duration');
  },
};

/* Copying without format */
document.addEventListener('copy', (e) => {
  e.preventDefault();
  const text = window
    .getSelection()
    .toString()
    .replace(/\u200B/g, '')
    .trim();
  const clipboardData = e.clipboardData || window.clipboardData || e.originalEvent.clipboardData;
  clipboardData.setData('Text', text);
});

// Config
setZodConfig();
setChartjsConfig();

// Essential data
vueGlobals.me = window.me;
Store.commit('global/setMe', window.me);

if (document.getElementById('vue')) {
  const app = createApp(Root);

  const pinia = createPinia();
  app.use(pinia);

  // Sentry
  setSentryConfig(app, Store.state.global.me, axios, router);

  if (import.meta.env.DEV) {
    app.config.performance = true;
  }

  app.config.globalProperties = {
    ...vueGlobals,
  };
  app.use(Store);
  app.use(router);
  vuePlugins.forEach((plugin) => app.use(plugin));
  app.mount('#vue');
}

if (window.hasLoggedOut)
  toast.fire({
    timer: 6000,
    title: "You've been logged out.",
    icon: 'success',
  });

// Inactivity on login page
if (new URL(location.href).searchParams.get('inactivity')) {
  const dayInMilliseconds = 24 * 60 * 60 * 1000;
  toast.fire({
    timer: dayInMilliseconds,
    title: "You've been logged out due to inactivity, please login again.",
    icon: 'info',
  });
  window.history.pushState({}, document.title, location.href.split('?')[0]);
}
