import axios, { AxiosError } from 'axios';
import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import { InvoiceFeeTypeSchema } from './InvoiceFeeType';
import { LedgerEntrySchema } from './LedgerEntry';

export const InvoiceFeeSchema = z.object({
  id: z.number(),
  total_amount: z.number().int(),
  total_commission_amount: z.number().int(),
  total_commission_percentage: z.number(),
  retail_commission_amount: z.number().int(),
  retail_commission_percentage: z.number(),
  mga_commission_amount: z.number().int(),
  mga_commission_percentage: z.number(),
  show_commission_on_pdf: z.boolean(),
  client_id: z.number(),
  invoice_id: z.number(),
  invoice_fee_type_id: z.number().nullish(),
  type: InvoiceFeeTypeSchema.nullish(),
  payment_fee_allocation: z
    .object({
      id: z.number().nullable(),
      invoice_fee_id: z.number(),
      payment_allocation_id: z.number(),
      total_amount: z.number().int(),
      fee_amount: z.number().int(),
      commission_amount: z.number().int(),
    })
    .nullish(),
  // receivable: z.object({
  //   commission: z.object({
  //     debit: z.number().int(),
  //     credit: z.number().int(),
  //   }),
  //   fee: z.object({
  //     debit: z.number().int(),
  //     credit: z.number().int(),
  //   }),
  // }),
  // payable: z.object({
  //   debit: z.number().int(),
  //   credit: z.number().int(),
  //   balance: z.number().int(),
  // }),
  model_type: z.string().nullish(),
  ledger_entries: z.array(LedgerEntrySchema).optional(),
});

export type InvoiceFee = z.infer<typeof InvoiceFeeSchema>;

type InvoiceFeePayload = {
  id: number;
  form: Partial<InvoiceFee>;
};

async function create({ form }: Pick<InvoiceFeePayload, 'form'>) {
  const response = await axios.post(`/api/invoice-fees`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: InvoiceFeeSchema,
    })
    .parseAsync(response.data);
}

async function update({ id, form }: InvoiceFeePayload) {
  const response = await axios.put(`/api/invoice-fees/${id}`, form).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });

  return z
    .object({
      data: InvoiceFeeSchema,
    })
    .parseAsync(response.data);
}

async function remove({ id }: Pick<InvoiceFeePayload, 'id'>) {
  return axios.delete(`/api/invoice-fees/${id}`).catch((error: AxiosError) => {
    Sentry.captureException(error);

    throw error;
  });
}

export default {
  create,
  update,
  remove,
};
